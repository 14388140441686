import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Header from "../../components/header.js"
import Footer from "../../components/footer.js"
import panel from '../../components/panel.module.css'
import styles from './css/aa.module.css'
import {Helmet} from 'react-helmet'

const AAPage = ( {data} ) => (
  <div>
    <Header />
      <Helmet>
          <title>Athanasia August</title>
      </Helmet>
    <div className={panel.panel}>
      <div className={panel.centeredText}>
        <h1 className={panel.h}>Athanasia August</h1>
      </div>
      <p>THE VENERABLE ART OF PROPHECY is a phenomenon constant throughout history. No matter the geography or the era, human civilizations invariably seek out the omniscience of destiny--as a source of wisdom, as a source of fear, and as a source of hope.</p>
      <p>For some, however, prophecy is an inescapable burden. Heroes, villains, Chosen Ones, and Fallen Ones alike must all contend with fate's heavy hand as it guides them along its path. They are the catalysts of revolution; the martyrs sacrificed for a greater good; the tired warriors seeking peace but finding only war, seeking solitude but finding glory, seeking love but finding loss. </p>
      <p>Nobody knows this better than Athanasia August, who, being the subject of precisely one million, two hundred thousand, and seventy-six prophecies, is at this point in her life well and truly sick of this shit. </p>
      <p>More on the first and third Monday of each month.</p>
      <div className={styles.borderLine}></div>
      <div className={panel.centeredText}>
        <h2 className={panel.h}>Catalogue</h2>
      </div>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div><Link to={node.frontmatter.path}>{node.frontmatter.date} | {node.frontmatter.title}</Link></div>
            ))}
    </div>
    <Footer />
  </div>
)

export default AAPage

export const query = graphql`
  query {
   allMarkdownRemark(
    sort: {fields: [frontmatter___date], order:DESC},
    filter: {fileAbsolutePath: {regex: "/content/aa/.*$/"}}
  ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          frontmatter{
            date(formatString: "YYYY-MM-DD")
            title
            path
          }
        }
      }
    }
  }
`
